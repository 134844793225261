import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import meowsicMod from './pages/triple-meowsic-mod.md';
import slimeCenter from './pages/slime-center.md';
import { ROUTES } from './constants';

export const Page = () => {
  const { page } = useParams();
  console.log('page', page);

  const [meowsicMarkdown, setMeowsicMarkdown] = useState('');
  useEffect(() => {
    fetch(meowsicMod)
      .then((response) => response.text())
      .then((text) => {
        setMeowsicMarkdown(text);
      });
  }, []);

  const [slimeCenterMarkdown, setSlimeCenterMarkdown] = useState('');
  useEffect(() => {
    fetch(slimeCenter)
      .then((response) => response.text())
      .then((text) => {
        setSlimeCenterMarkdown(text);
      });
  }, []);

  const routes: Record<string, any> = { ...ROUTES };
  routes['triple-meowsic-mod'].markdown = meowsicMarkdown;
  routes['slime-center'].markdown = slimeCenterMarkdown;

  const allMarkdownLoaded = !!meowsicMarkdown && !!slimeCenterMarkdown;
  if (
    !page ||
    !Object.keys(routes).includes(page ?? '') ||
    !allMarkdownLoaded
  ) {
    return null;
  }

  console.log('routes', routes);

  return (
    <div className='markdown-container'>
      <Markdown remarkPlugins={[remarkGfm]}>{routes[page].markdown}</Markdown>
    </div>
  );
};
