import { Link } from 'react-router-dom';
import { ROUTES } from './constants';

export const Home = () => {
  console.log(ROUTES);
  return (
    <div>
      <h2>home</h2>
      <p>here is some regular text</p>
      <ul>
        {Object.keys(ROUTES).map((route) => (
          <li>
            <Link to={`/${route}`}>{ROUTES[route].title}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
