export const drawBg = () => {
  const canvas = document.querySelector('canvas');
  if (!canvas) {
    console.error('no canvas');
    return;
  }
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    console.error('no ctx');
    return;
  }

  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  let letters: any = 'slime  ';
  letters = letters.split('');

  const fontSize = 10;
  const columns = canvas.width / fontSize;

  // Setting up the drops
  const drops: any = [];
  for (let i = 0; i < columns; i++) {
    drops[i] = 1;
  }

  const draw = () => {
    if (!canvas) {
      console.error('no canvas');
      return;
    }
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      console.error('no ctx');
      return;
    }
    ctx.fillStyle = 'rgba(0, 0, 0, .05)';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    for (let i = 0; i < drops.length; i++) {
      if (Math.random() < 0.3) {
        const text = letters[i % letters.length];
        ctx.fillStyle = '#0f0';
        ctx.fillText(text, i * fontSize, drops[i] * fontSize);
        drops[i]++;
        if (drops[i] * fontSize > canvas.height && Math.random() > 0.95) {
          drops[i] = 0;
        }
      }
    }
  };

  setInterval(draw, 66);
};
