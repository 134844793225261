import { Link } from 'react-router-dom';

export const SiteWrapper = ({ children }: React.PropsWithChildren) => {
  return (
    <div>
      <h1 style={{ paddingBottom: 32 }}>
        <Link to='/' style={{ fontFamily: 'Comfortaa' }}>
          slimesmith
        </Link>
      </h1>
      {children}

      <a
        href={process.env.PUBLIC_URL + '/slimesmith-rss.xml'}
        target='_blank'
        rel='noreferrer'
      >
        rss
      </a>
    </div>
  );
};
