import React, { useEffect } from 'react';
import './App.css';
import { drawBg } from './drawBg';
import { PrimeReactProvider } from 'primereact/api';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Home } from './Home';
import { SiteWrapper } from './SiteWrapper';
import { Page } from './Page';

const App = () => {
  useEffect(drawBg, []);
  const router = createBrowserRouter(
    [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/:page',
        element: <Page />,
      },
    ].map((route) => ({
      ...route,
      element: <SiteWrapper key={route.path}>{route.element}</SiteWrapper>,
    }))
  );
  return (
    <PrimeReactProvider>
      <canvas />
      <div className='App col-8 col-offset-2 main-container'>
        <div id='effect' />
        <RouterProvider router={router} />
      </div>
    </PrimeReactProvider>
  );
};

export default App;
